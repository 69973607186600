import {ActionReducerMap, createFeatureSelector} from '@ngrx/store';
import { CheckoutState } from './ngrx/checkout/checkout.state';
import { checkoutReducer } from './ngrx/checkout/checkout.reducer';
export interface AppState {
    checkoutState: CheckoutState
}

export const selectCheckoutState = createFeatureSelector<CheckoutState>('checkoutState');

export const reducers: ActionReducerMap<AppState> = {
    checkoutState: checkoutReducer,
}
