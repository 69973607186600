import { Injectable } from "@angular/core";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { Store } from "@ngrx/store";
import { CheckoutService } from "../../services/data/checkout.service";
import { Observable, catchError, map, mergeMap, of, withLatestFrom } from "rxjs";
import { CheckoutDto } from "../../models/checkout-dto.modal";
import { checkStatusTransaction, errorCheckout, findTransactionById, proceedMobileMoneyTransaction, setCheckoutDto, setCheckoutTransactionDto } from "./checkout.actions";
import { CheckoutTransactionDto } from "../../models/checkout-transaction-dto";
import { selectCheckoutState } from "../../core.state";

@Injectable()
export class CheckoutEffects {

    findTransactionById = createEffect(() => this.actions$.pipe(
        ofType(findTransactionById),
        mergeMap(({id}) => this.parseSetCheckoutTransactionDto(this.checkoutService.getTransaction(id)))
    ))

    proceedMobileMoneyTransaction = createEffect(() => this.actions$.pipe(
        ofType(proceedMobileMoneyTransaction),
        mergeMap(({transactionId, paymentMode, paymentMethod, paymentNumber}) => this.parseSetCheckoutDto(this.checkoutService.proceedMobileMoneyTransaction(
            transactionId, paymentMode, paymentMethod, paymentNumber
        )))
    ))

    checkStatusTransaction = createEffect(() => this.actions$.pipe(
        ofType(checkStatusTransaction),
        mergeMap(({id}) => this.parseSetCheckoutTransactionDto(this.checkoutService.getTransaction(id)))
    ))

    constructor(private actions$: Actions,
        private storeService: Store,
        private checkoutService: CheckoutService)
    {}

    parseSetCheckoutDto(obs: Observable<CheckoutDto>) {
        return obs.pipe(
          map(
            (checkout: CheckoutDto) => setCheckoutDto({checkout})
          ), catchError((data) => of(errorCheckout({messages: [data.message]})))
        )
    }

    parseSetCheckoutTransactionDto(obs: Observable<CheckoutTransactionDto>) {
        return obs.pipe(
          map(
            (transaction: CheckoutTransactionDto) => setCheckoutTransactionDto({transaction})
          ), catchError((data) => of(errorCheckout({messages: [data.message]})))
        )
    }
}