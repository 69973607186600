export class routes {
  public static Url: string = '';

  public static get baseUrl(): string {
    return routes.Url;
  }
  public static get errorPage404(): string {
    return this.baseUrl + '/error/404';
  }
  public static get errorPage500(): string {
    return this.baseUrl + '/error/500';
  }
}
