import {Component, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {SpinnerService} from './core/core.index';
import {APP_ENUMS} from './core/enums/app.enums';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from './core/services/local-storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
    title = 'Leuton Pay : checkout';

    constructor(
        private spinner: SpinnerService,
        private router: Router,
        private translateService: TranslateService,
        private localStorageService: LocalStorageService) {

        this.translateService.setDefaultLang(APP_ENUMS.PREFIX_DEFAULT_LANGUAGE);
        this.translateService.use(this.localStorageService.localLangValue);
        document.documentElement.lang = this.localStorageService.localLangValue;
        // show and hide spinner in every route change
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                this.spinner.show();
            }
            if (event instanceof NavigationEnd) {
                setTimeout(() => {
                    this.spinner.hide();
                }, 800);
            }
        });
    }
}
