import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {CheckoutTransactionDto} from "../../models/checkout-transaction-dto";
import {CheckoutDto} from '../../models/checkout-dto.modal';
import {Configuration, configurationToken} from "../../../../configuration/configuration";

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
    providedIn: 'root',
})
export class CheckoutService {

    private readonly basePaymentUrl: string;

    constructor(private http: HttpClient, @Inject(configurationToken) private configuration: Configuration) {
        this.basePaymentUrl = configuration.baseUrl + '/v1/checkout';
    }

    getTransaction(id: string): Observable<CheckoutTransactionDto> {
        return this.http.get<CheckoutTransactionDto>(this.basePaymentUrl + '/' + id);
    }

    proceedMobileMoneyTransaction(transactionId: string, paymentMode: string, paymentMethod: string, paymentNumber: string) {
        return this.http.post<CheckoutDto>(this.basePaymentUrl + '/proceed/' + transactionId, {
            paymentNumber: paymentNumber,
            paymentMethod: paymentMethod,
            paymentMode: paymentMode
        });
    }
}
