import {createAction, props} from '@ngrx/store';
import { CheckoutDto } from '../../models/checkout-dto.modal';
import { CheckoutTransactionDto } from '../../models/checkout-transaction-dto';

export const setCheckoutDto = createAction('[CheckoutDto] set checkout dto', props<{checkout: CheckoutDto}>());
export const setCheckoutTransactionDto = createAction('[CheckoutTransactionDto] set checkout transaction dto', props<{transaction: CheckoutTransactionDto}>());
export const errorCheckout = createAction('[ERRORS] Error checkout', props<{messages: string[]}>());

export const checkStatusTransaction = createAction('[CheckoutTransactionDto] check status of transaction', props<{id: string}>());

export const findTransactionById = createAction('[CheckoutTransactionDto] find transaction by id', props<{id: string}>());
export const proceedMobileMoneyTransaction = createAction('[CheckoutDto] proceed mobile money transaction', props<{transactionId: string, paymentMode: string, paymentMethod: string, paymentNumber: string}>());
