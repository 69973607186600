import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { APP_ENUMS } from '../enums/app.enums';
@Injectable({ providedIn: 'root' })
export class LocalStorageService {

  private localLangSubject: BehaviorSubject<string>;
  public localLang: Observable<string>;

  constructor(
  ) {

    this.localLangSubject = new BehaviorSubject<string>(localStorage.getItem(APP_ENUMS.PREFIX_LOCAL_LANG) || APP_ENUMS.PREFIX_DEFAULT_LANGUAGE);
    this.localLang = this.localLangSubject.asObservable();
  }

  public get localLangValue(): string {
    return this.localLangSubject.value;
  }
  public setLocalLangValue(lang: string): void {
    localStorage.setItem(APP_ENUMS.PREFIX_LOCAL_LANG , lang);
    this.localLangSubject.next(lang);
  }
}