import { Action, createReducer, on } from "@ngrx/store";
import { DataStateEnum } from "../../enums/data.state.enum";
import { CheckoutState } from "./checkout.state";
import { checkStatusTransaction, findTransactionById, proceedMobileMoneyTransaction, setCheckoutDto, setCheckoutTransactionDto } from "./checkout.actions";

const initState: CheckoutState = {
    checkout: {},
    transaction: {},
    dataState: DataStateEnum.INITIAL,
    messages: []
}

const reducer = createReducer(initState,
    on(setCheckoutDto, (state, {checkout}) => ({...state, checkout: checkout, dataState: DataStateEnum.SUCCESS, messages: []})),
    on(setCheckoutTransactionDto, (state, {transaction}) => ({...state, transaction: transaction, dataState: DataStateEnum.SUCCESS, messages: []})),
    on(checkStatusTransaction, proceedMobileMoneyTransaction, state => ({...state, dataState: DataStateEnum.LOADING})),
    on(findTransactionById, state => ({...state, dataState: DataStateEnum.INITIAL}))
);

export function checkoutReducer(
    state: CheckoutState | undefined,
    action: Action
): CheckoutState {
    return reducer(state, action);
}
